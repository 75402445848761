<script>
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";
import { minLength, required, sameAs } from "vuelidate/lib/validators";

/**
 * Change password component
 */
export default {
  page: {
    title: "Zmiana hasła",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      preloader: true,
      submitted: false,
      form: {
        newPassword1: "",
        newPassword2: "",
      },
      info: true,
      error: null,
      success: null,
      isChangeSuccess: false,
      isChangeError: false,
      footerYear: new Date().getFullYear()
    };
  },
  validations: {
    form: {
      newPassword1: { required, minLength: minLength(12) },
      newPassword2: {
        required,
        minLength: minLength(12),
        sameAsPassword1: sameAs("newPassword1"),
      },
    },
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryChangePassword() {
      this.formSubmit();
      if (this.$v.form.$error === true) {
        return false;
      }
      return (
        this.changePasswordWithToken({
          payload: this.form,
          token: this.$route.params.token,
        })
          // eslint-disable-next-line no-unused-vars
          .then((token) => {
            this.newPassword1 = null;
            this.newPassword2 = null;
            this.success =
              "Hasło zostało zmienione pomyślnie! Zaloguj się używając nowego hasła.";
            this.isChangeSuccess = true;
            this.isChangeError = false;
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            this.isChangeSuccess = false;
            this.error =
              "Wystąpił problem ze zmianą hasła. Spróbuj rozpocząć procedurę odzyskania hasła od nowa.";
            this.isChangeError = true;
          })
      );
    },
    formSubmit() {
      this.submitted = true;
      this.$v.form.$touch();
    },
    decryptMailtoEmail(encoded) {
      var address = atob(encoded);
      window.location.href = "mailto:" + address;
    }
  },
};
</script>

<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-primary">
              <div class="text-primary text-center p-4">
                <h5 class="text-white font-size-20 p-2">Zmiana hasła - krok 2</h5>
                <a href="/" class="logo logo-admin">
                  <img src="@/assets/images/si-pay-logo.png" height="24" alt="logo" />
                </a>
              </div>
            </div>
            <div class="card-body p-4">
              <div class="p-3 mt-4">
                <b-alert v-model="isChangeError" class="mt-3 mt-2" variant="danger" dismissible>
                  {{error}}
                </b-alert>
                <b-alert v-model="isChangeSuccess" class="mt-3 mt-2" variant="success" dismissible>
                  {{success}}
                </b-alert>
                <form class="needs-validation" @submit.prevent="formSubmit">
                  <div class="form-group">
                    <label for="newPassword1">Nowe hasło</label>
                    <input id="newPassword1" v-model="form.newPassword1" type="password" class="form-control" placeholder="Nowe hasło*" :class="{ 'is-invalid': submitted && $v.form.newPassword1.$error }" />
                    <div v-if="submitted && $v.form.newPassword1.$error" class="invalid-feedback">
                      <span v-if="!$v.form.newPassword1.required">Ta wartość jest wymagana.<br /></span>
                      <span v-if="!$v.form.newPassword1.minLength">Wartość musi zawierać minimum 12 znaków.<br /></span>
                      <span v-if="!$v.form.newPassword2.sameAsPassword1">Hasła są różne.</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="newPassword1">Powtórz hasło</label>
                    <input id="newPassword2" v-model="form.newPassword2" type="password" class="form-control" placeholder="Nowe hasło - powtórz*" :class="{ 'is-invalid': submitted && $v.form.newPassword2.$error }" />
                    <div v-if="submitted && $v.form.newPassword2.$error" class="invalid-feedback">
                      <span v-if="!$v.form.newPassword2.required">Ta wartość jest wymagana.<br /></span>
                      <span v-if="!$v.form.newPassword2.minLength">Wartość musi zawierać minimum 12 znaków.<br /></span>
                      <span v-if="!$v.form.newPassword2.sameAsPassword1">Hasła są różne.</span>
                    </div>
                    <span class="mt-5 font-size-11">* Nowe hasło musi mieć długość minimum 12 znaków. <br />Najlepiej, jeżeli będzie składało się z małych i dużych liter oraz cyfr i znaków specjalnych.</span>
                  </div>

                  <div class="form-group row mb-0">
                    <div class="col-12 text-right">
                      <button class="btn btn-primary w-md" v-on:click="tryChangePassword" type="submit">Zmień hasło
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
          <div class="mt-5 text-center">
            <p>
              Pamiętasz swoje hasło?
              <router-link tag="a" to="/login" class="font-weight-medium text-primary">Zaloguj się</router-link>
            </p>
            <p class="mb-0">
            © {{ footerYear }} <img src="@/assets/images/si-pay-logo.png" alt height="15" class="mt-n1" /> SI-PAY <span class="text-muted small">v1.2.0</span>
            <br/>
            <!-- <a href="#" @click="decryptMailtoEmail('aXBwQGltcGVsLnBs')">Pomoc</a> -->
            </p>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<style lang="scss" module></style>
